import {
    Button,
    makeStyles,
    SelectTabData,
    SelectTabEvent,
    shorthands,
    Tab,
    TabList,
    TabValue,
    tokens,
    Tooltip,
} from '@fluentui/react-components';
import { FC, useState } from 'react';
import {
    AppsListRegular,
    ChatRegular,
    CheckmarkStarburstRegular,
    DataTrendingRegular,
    DocumentTextRegular,
    SettingsRegular,
    WindowSettingsRegular,
} from '@fluentui/react-icons';
import { Loading } from './Loading';
import { ChatView } from './ChatView';
import * as React from 'react';
import { AppState } from '../../App';
import { SettingsDialog } from '../header/settings-dialog/SettingsDialog';
import { customTokens } from '../../styles';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { KpiWindow } from '../kpi/KpiWindow';
import { DocumentsWindow } from '../documents/DocumentsWindow';
import { SettingsWindow } from '../settings/SettingsWindow';
import { TestWindow } from '../test/TestWindow';
import { ArchiveWindow } from '../archive/ArchiveWindow';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    bar: {
        backgroundImage: 'linear-gradient(180deg, #08204A 20%, #FF5A10 90%)',
        boxShadow: 'inset -7px 0 9px -7px rgba(8, 32, 74, 0.5)', // #08204A
    },
    item: {
        ...shorthands.padding('15px'),
        color: 'white',
        fontSize: tokens.fontSizeBase500,
        '& > .fui-Tab__icon': {
            color: customTokens.colorNeutralForegroundStaticInverted,
            width: '24px',
            height: '24px',
            fontSize: '24px',
        },
        '&:hover > .fui-Tab__icon': {
            color: customTokens.colorNeutralForegroundStaticInverted,
        },
        '&[aria-selected=true] > .fui-Tab__icon': {
            color: customTokens.colorBrandForeground1,
        },
        '&[aria-selected=true]:hover > .fui-Tab__icon': {
            color: customTokens.colorBrandForeground2,
        },
    },
    content: {
        flexGrow: 1,
    },
    settings: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: '8px',
    },
    settingsButton: {
        color: customTokens.colorNeutralForegroundStaticInverted,
        ':hover': {
            color: customTokens.colorNeutralForegroundStaticInverted,
        },
    },
});

interface IMainViewParams {
    appState: AppState;
}

export const MainView: FC<IMainViewParams> = ({ appState }) => {
    const classes = useClasses();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);

    const [selectedValue, setSelectedValue] = React.useState<TabValue>('chat');
    const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    return (
        <div className={classes.container}>
            <TabList
                className={classes.bar}
                selectedValue={selectedValue}
                onTabSelect={onTabSelect}
                vertical
                reserveSelectedTabSpace
            >
                <Tooltip content="Chat" relationship="description" positioning="after" withArrow>
                    <Tab icon={<ChatRegular />} className={classes.item} value="chat" />
                </Tooltip>
                {activeUserInfo?.canQuality && (
                    <Tooltip content="Archiv" relationship="description" positioning="after" withArrow>
                        <Tab icon={<AppsListRegular />} className={classes.item} value="archive" />
                    </Tooltip>
                )}
                {activeUserInfo?.canReport && (
                    <Tooltip content="Statistiken" relationship="description" positioning="after" withArrow>
                        <Tab icon={<DataTrendingRegular />} className={classes.item} value="kpi" />
                    </Tooltip>
                )}
                {activeUserInfo?.canManage && (
                    <Tooltip content="Dokumente" relationship="description" positioning="after" withArrow>
                        <Tab icon={<DocumentTextRegular />} className={classes.item} value="documents" />
                    </Tooltip>
                )}
                {activeUserInfo?.canManage && (
                    <Tooltip content="Einstellungen" relationship="description" positioning="after" withArrow>
                        <Tab icon={<SettingsRegular />} className={classes.item} value="settings" />
                    </Tooltip>
                )}
                {activeUserInfo?.canManage && (
                    <Tooltip content="Test" relationship="description" positioning="after" withArrow>
                        <Tab icon={<CheckmarkStarburstRegular />} className={classes.item} value="test" />
                    </Tooltip>
                )}
                {activeUserInfo?.canManage && (
                    <div className={classes.settings}>
                        <Tooltip content="Settings" relationship="description" positioning="after" withArrow>
                            <Button
                                size="large"
                                className={classes.settingsButton}
                                appearance="transparent"
                                icon={<WindowSettingsRegular />}
                                onClick={() => {
                                    setOpenSettingsDialog(true);
                                }}
                            />
                        </Tooltip>
                    </div>
                )}
            </TabList>
            <div className={classes.content}>
                {selectedValue === 'chat' && appState === AppState.LoadingChats && <Loading text="Loading chats..." />}
                {selectedValue === 'chat' && appState === AppState.Chat && <ChatView />}
                {selectedValue === 'archive' && <ArchiveWindow />}
                {selectedValue === 'documents' && <DocumentsWindow />}
                {selectedValue === 'kpi' && <KpiWindow />}
                {selectedValue === 'settings' && <SettingsWindow />}
                {selectedValue === 'test' && <TestWindow />}
            </div>
            <SettingsDialog
                open={openSettingsDialog}
                closeDialog={() => {
                    setOpenSettingsDialog(false);
                }}
            />
        </div>
    );
};
